var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bt-cif pa-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"rounded-pill purple lighten-1 me-2 px-2 d-flex align-center justify-center py-2 elevation-3"},[_c('v-icon',{attrs:{"dense":"","color":"white"}},[_vm._v("mdi-account-arrow-left")])],1),_c('div',{staticClass:"ml-1 d-flex justify-center align-center"},[_c('span',{staticClass:"font-weight-bold align-center"},[_vm._v(" Registrasi Anggota ")])])])])],1),_c('v-container',{staticClass:"pa-0 mb-5"},[_c('div',{},[_c('div',{staticClass:"w-full elevation-3 rounded-lg pa-5 my-5",style:({
          backgroundColor: _vm.$vuetify.theme.dark
            ? 'var(--dark-bg)'
            : 'var(--light-bg)',
        })},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"color":_vm.$vuetify.theme.dark
                    ? 'background-color: white'
                    : 'background-color: black',"outlined":"","autocomplete":"off","items":_vm.list.hari_transaksi,"label":"Hari Transaksi","required":"","rules":[(v) => !!v || 'Hari Transaksi wajib diisi'],"item-value":"value","item-text":"text","clearable":""},model:{value:(_vm.form.hari_transaksi),callback:function ($$v) {_vm.$set(_vm.form, "hari_transaksi", $$v)},expression:"form.hari_transaksi"}}),_c('v-autocomplete',{attrs:{"color":_vm.$vuetify.theme.dark
                    ? 'background-color: white'
                    : 'background-color: black',"autocomplete":"off","outlined":"","items":_vm.list.cm,"label":"Rembug","required":"","rules":[(v) => !!v || 'Rembug is required']},model:{value:(_vm.form.cm_code),callback:function ($$v) {_vm.$set(_vm.form, "cm_code", $$v)},expression:"form.cm_code"}}),_c('v-autocomplete',{attrs:{"color":_vm.$vuetify.theme.dark
                    ? 'background-color: white'
                    : 'background-color: black',"autocomplete":"off","outlined":"","items":_vm.list.cif,"label":"ID Anggota","required":"","rules":[(v) => !!v || 'ID Anggota is required']},model:{value:(_vm.form.cif_no),callback:function ($$v) {_vm.$set(_vm.form, "cif_no", $$v)},expression:"form.cif_no"}}),_c('v-text-field',{attrs:{"color":_vm.$vuetify.theme.dark
                    ? 'background-color: white'
                    : 'background-color: black',"autocomplete":"off","outlined":"","label":"Nama Lengkap (Sesuai KTP)","readonly":"","required":"","rules":[
                  (v) => !!v || 'Nama Lengkap (Sesuai KTP) is required',
                ]},model:{value:(_vm.form.nama),callback:function ($$v) {_vm.$set(_vm.form, "nama", $$v)},expression:"form.nama"}}),_c('v-text-field',{attrs:{"color":_vm.$vuetify.theme.dark
                    ? 'background-color: white'
                    : 'background-color: black',"autocomplete":"off","outlined":"","label":"Saldo Pokok","disabled":""},model:{value:(_vm.form.saldo_pokok),callback:function ($$v) {_vm.$set(_vm.form, "saldo_pokok", $$v)},expression:"form.saldo_pokok"}}),_c('v-text-field',{attrs:{"color":_vm.$vuetify.theme.dark
                    ? 'background-color: white'
                    : 'background-color: black',"autocomplete":"off","outlined":"","label":"Saldo Margin","disabled":""},model:{value:(_vm.form.saldo_margin),callback:function ($$v) {_vm.$set(_vm.form, "saldo_margin", $$v)},expression:"form.saldo_margin"}}),_c('v-text-field',{attrs:{"color":_vm.$vuetify.theme.dark
                    ? 'background-color: white'
                    : 'background-color: black',"autocomplete":"off","outlined":"","label":"Saldo Simsus","disabled":""},model:{value:(_vm.form.saldo_simsus),callback:function ($$v) {_vm.$set(_vm.form, "saldo_simsus", $$v)},expression:"form.saldo_simsus"}}),_c('v-text-field',{attrs:{"color":_vm.$vuetify.theme.dark
                    ? 'background-color: white'
                    : 'background-color: black',"autocomplete":"off","outlined":"","label":"Sihata Mingguan","disabled":""},model:{value:(_vm.form.sihata_mingguan),callback:function ($$v) {_vm.$set(_vm.form, "sihata_mingguan", $$v)},expression:"form.sihata_mingguan"}}),_c('v-text-field',{attrs:{"color":_vm.$vuetify.theme.dark
                    ? 'background-color: white'
                    : 'background-color: black',"autocomplete":"off","outlined":"","label":"Saldo Simwa","disabled":""},model:{value:(_vm.form.saldo_simwa),callback:function ($$v) {_vm.$set(_vm.form, "saldo_simwa", $$v)},expression:"form.saldo_simwa"}}),_c('v-text-field',{attrs:{"color":_vm.$vuetify.theme.dark
                    ? 'background-color: white'
                    : 'background-color: black',"autocomplete":"off","outlined":"","label":"Saldo Sukarela","disabled":""},model:{value:(_vm.form.saldo_sukarela),callback:function ($$v) {_vm.$set(_vm.form, "saldo_sukarela", $$v)},expression:"form.saldo_sukarela"}}),_c('v-text-field',{attrs:{"color":_vm.$vuetify.theme.dark
                    ? 'background-color: white'
                    : 'background-color: black',"autocomplete":"off","outlined":"","label":"Saldo Tabungan Lainnya","disabled":""},model:{value:(_vm.form.saldo_lainnya),callback:function ($$v) {_vm.$set(_vm.form, "saldo_lainnya", $$v)},expression:"form.saldo_lainnya"}}),_c('v-text-field',{attrs:{"color":_vm.$vuetify.theme.dark
                    ? 'background-color: white'
                    : 'background-color: black',"autocomplete":"off","outlined":"","label":"Saldo Simpanan Pokok","disabled":""},model:{value:(_vm.form.simpanan_pokok),callback:function ($$v) {_vm.$set(_vm.form, "simpanan_pokok", $$v)},expression:"form.simpanan_pokok"}}),_c('v-text-field',{attrs:{"color":_vm.$vuetify.theme.dark
                    ? 'background-color: white'
                    : 'background-color: black',"autocomplete":"off","outlined":"","label":"Saldo SDA","disabled":""},model:{value:(_vm.form.saldo_sda),callback:function ($$v) {_vm.$set(_vm.form, "saldo_sda", $$v)},expression:"form.saldo_sda"}}),_c('v-text-field',{attrs:{"color":_vm.$vuetify.theme.dark
                    ? 'background-color: white'
                    : 'background-color: black',"autocomplete":"off","outlined":"","label":"Saldo Hutang","disabled":""},model:{value:(_vm.form.saldo_hutang),callback:function ($$v) {_vm.$set(_vm.form, "saldo_hutang", $$v)},expression:"form.saldo_hutang"}}),_c('v-text-field',{attrs:{"color":_vm.$vuetify.theme.dark
                    ? 'background-color: white'
                    : 'background-color: black',"autocomplete":"off","outlined":"","label":"Saldo Simpanan","disabled":""},model:{value:(_vm.form.saldo_simpanan),callback:function ($$v) {_vm.$set(_vm.form, "saldo_simpanan", $$v)},expression:"form.saldo_simpanan"}}),_c('v-text-field',{attrs:{"color":_vm.$vuetify.theme.dark
                    ? 'background-color: white'
                    : 'background-color: black',"autocomplete":"off","outlined":"","label":"Setoran Tambahan","disabled":""},model:{value:(_vm.form.setoran_tambahan),callback:function ($$v) {_vm.$set(_vm.form, "setoran_tambahan", $$v)},expression:"form.setoran_tambahan"}}),_c('v-text-field',{attrs:{"color":_vm.$vuetify.theme.dark
                    ? 'background-color: white'
                    : 'background-color: black',"autocomplete":"off","outlined":"","label":"Penarikan Sukarela","disabled":""},model:{value:(_vm.form.penarikan_tabungan_sukarela),callback:function ($$v) {_vm.$set(_vm.form, "penarikan_tabungan_sukarela", $$v)},expression:"form.penarikan_tabungan_sukarela"}}),_c('v-text-field',{attrs:{"type":"date","color":_vm.$vuetify.theme.dark
                    ? 'background-color: white'
                    : 'background-color: black',"autocomplete":"off","outlined":"","label":"Tanggal Keluar","required":"","rules":[(v) => !!v || 'Tanggal Keluar is required']},model:{value:(_vm.form.tanggal_mutasi),callback:function ($$v) {_vm.$set(_vm.form, "tanggal_mutasi", $$v)},expression:"form.tanggal_mutasi"}}),_c('v-autocomplete',{attrs:{"color":_vm.$vuetify.theme.dark
                    ? 'background-color: white'
                    : 'background-color: black',"autocomplete":"off","outlined":"","items":_vm.list.reason,"label":"Alasan","required":"","rules":[(v) => !!v || 'Alasan is required']},model:{value:(_vm.form.alasan),callback:function ($$v) {_vm.$set(_vm.form, "alasan", $$v)},expression:"form.alasan"}}),_c('v-textarea',{attrs:{"color":_vm.$vuetify.theme.dark
                    ? 'background-color: white'
                    : 'background-color: black',"autocomplete":"off","outlined":"","label":"Keterangan","required":"","rules":[(v) => !!v || 'Keterangan is required']},model:{value:(_vm.form.keterangan),callback:function ($$v) {_vm.$set(_vm.form, "keterangan", $$v)},expression:"form.keterangan"}})],1)],1)],1)],1)]),_c('v-row',{staticStyle:{"margin-bottom":"50px"}},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('router-link',{attrs:{"to":"/keanggotaan/dashboard"}},[_c('v-btn',{staticClass:"purple lighten-1 white--text",attrs:{"block":""}},[_vm._v("Kembali ")])],1)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"purple lighten-1 white--text",attrs:{"block":"","disabled":!_vm.isFormValid},on:{"click":_vm.doSave}},[_vm._v(" Simpan ")])],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":5000},model:{value:(_vm.alert.show),callback:function ($$v) {_vm.$set(_vm.alert, "show", $$v)},expression:"alert.show"}},[_vm._v(" "+_vm._s(_vm.alert.msg)+" ")]),_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }